import React from "react";
import Intro from "./intro";
import Addface from "./addface";
import Detectface from "./detecface";

const routes = [
  {
    route: "/",
    component: <Intro />,
  },
  {
    route: "/addface",
    component: <Addface />,
  },
  {
    route: "/detectface",
    component: <Detectface />,
  },
];

export default routes;
