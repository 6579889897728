import './App.css';
import * as React from 'react';
// import { useState , useRef } from 'react';
// import { Grid } from '@mui/material';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
// import Link from '@mui/material/Link';
import { makeStyles } from '@mui/styles';
import facebg1 from './images/Facial-Recognition-1.png'
import logo from './images/codelogo.png'
// import logo1 from './images/codelogo2.jpeg'

const useStyles = makeStyles((theme) => ({
  Button: {
      background: 'linear-gradient(195deg, rgb(73, 163, 241), rgb(26, 115, 232))',
      borderRadius: 3,
      // height: 48,
      '&:hover': {
        backgroundColor: '#1662c4',
      }
    },
}));

function Intro() {
  const classes = useStyles()

  const addface = () => {
    console.log("1")
    window.location.href = '../addface'
  }

  const detecface = () => {
    console.log("2")
    window.location.href = '../detectface'
  }

  return (
    <div className="App-1">
        <Box className="box1">
            <img src={logo} alt="logo" className='image1' ></img>
            <Typography ml="14px" mb="20px" mt='-30px' sx={{fontSize:'10px'}}>CODEBUGGED</Typography>
            {/* <img src={logo1} alt="logo" width="100px" height="90px"></img> */}
            <h1 className='h1'>Face Recognition</h1>
            <Typography variant="body2" color="common.white" pr={2} mt="-15px" sx={{textAlign:'justify'}}>Unlock a new era of security
             and convenience with our cutting-edge face recognition technology. Say goodbye to passwords and keys simply look, and 
             you're in. Experience the future of identity authentication today, and join us in redefining the way you interact with 
             the world. Embrace a world where your face is your key to a safer and more accessible tomorrow.</Typography>
            <Box className="box-buttons">
              <Button variant="contained" size='medium' className={classes.Button} onClick={addface}>Add face</Button>
              <Button variant="contained" size='medium'className={classes.Button} onClick={detecface}>Detect Face</Button>
            </Box>
        </Box>
        <Box className="box2">
            <img src={facebg1} className='introimage' alt="face"></img>  
        </Box>
    </div>
  );
}

export default Intro;
