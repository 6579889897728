import './App.css';
import * as React from 'react';
import { useState , useEffect } from 'react';
// import { Grid } from '@mui/material';
import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import Typography from '@mui/material/Typography';
// import Link from '@mui/material/Link';
import { makeStyles } from '@mui/styles';
import axios from 'axios';
import ArrowCircleLeftSharpIcon from '@mui/icons-material/ArrowCircleLeftSharp';
import IconButton from '@mui/material/IconButton';
import { useLocation } from 'react-router-dom';
import JSZip from 'jszip';
import CircularProgress from '@mui/material/CircularProgress';
import facebg from './images/Facial-Recognition.png'
// import facebg1 from './images/Facial-Recognition-1.png'

const useStyles = makeStyles((theme) => ({
    TextField: {
        '& .MuiOutlinedInput-root': {
          '& fieldset': {
            borderColor: '#ffffff',
          },
          '&:hover fieldset': {
            borderColor: '#ffffff',
          },
          '&.Mui-focused fieldset': {
            borderColor: '#ffffff',
          },
        },
        '& .MuiInputBase-input::placeholder': {
          color: '#ffffff', // Placeholder text color
        },
        '& .MuiInputBase-input::-webkit-input-placeholder': {
          color: '#ffffff', // Placeholder text color for Webkit browsers (e.g., Chrome, Safari)
        },
        '& .MuiInputBase-input:-moz-placeholder': {
          color: '#ffffff', // Placeholder text color for Firefox
        },
        '& .MuiInputBase-input::-moz-placeholder': {
          color: '#ffffff', // Placeholder text color for Firefox
        },
        '& .MuiInputBase-input:-ms-input-placeholder': {
          color: '#ffffff', // Placeholder text color for Microsoft Edge
        },
      },      
      
    Button: {
        background: 'linear-gradient(195deg, rgb(73, 163, 241), rgb(26, 115, 232))',
        borderRadius: 3,
        // height: 48,
        '&:hover': {
          backgroundColor: '#1662c4',
        }
      },
  }));

function Addface() {
    const classes = useStyles()
    const location = useLocation();

    const [name,setName] = useState('')
    const [isButtonDisabled, setIsButtonDisabled] = useState(true);
    const [loading,setLoading] = useState(false)
    const [disablebutton, setDisablebutton] = useState(false)
    
    const VisuallyHiddenInput = styled('input')({
        clip: 'rect(0 0 0 0)',
        clipPath: 'inset(50%)',
        height: 1,
        overflow: 'hidden',
        position: 'absolute',
        bottom: 0,
        left: 0,
        whiteSpace: 'nowrap',
        width: 1,
      });

    const handleFileUpload = (event) => {
        setLoading(true)
        setDisablebutton(true)
        const files = event.target.files;
        const formData = new FormData();
        formData.append('name', name);
      
        // Create a JSZip instance
        const zip = new JSZip();
      
        let maxFiles;

        if( files.length === 0 ){
          maxFiles = 0
        }

        if( files.length === 1 ){
          maxFiles = 1
        }

        if( files.length === 2 ){
          maxFiles = 2
        }

        if( files.length === 3 ){
          maxFiles = 3
        }

        // Iterate over selected files and add them to the zip file
        for(let index = 0 ; index < maxFiles ; index++) {
          // console.log(index)
          const fileName = `${name}_image${index}.jpg`; // Adjust the file name as needed
          zip.file(fileName, files[index]);
        }
      
        // Generate the zip file as a Blob
        zip.generateAsync({ type: 'blob' })
          .then(function (zipBlob) {
            // Append the zip file Blob to the FormData
            formData.append('file', zipBlob, 'images.zip');
      
            // Make a POST request to your API endpoint
            axios.post('https://facerecognition.codebugged.com/upload', formData, {
              headers: {
                'Content-Type': 'multipart/form-data',
              },
            })
            .then(response => {
              // Handle success
              console.log('Upload successful', response);
              setDisablebutton(false)
              setLoading(false)
              setName('');
              window.location.href = '/detectface';
            })
            .catch(error => {
              // Handle error
              console.error('Error uploading files', error);
            });
          });
      }
      

      const previouspage = () => {
        if (location.pathname === '/addface') {
          window.location.href = '/';
        }
      }

    useEffect(() => {
        setIsButtonDisabled(name === ''); // Enable the button only if name is not empty
      }, [name]);

  return (
    <div className="App-1">
        <Box className="box1">
            <Box className="box" mb="10px">
                <IconButton color='inherit' size="large"  onClick={previouspage} disabled={disablebutton}> 
                    <ArrowCircleLeftSharpIcon/> 
                </IconButton>
                <Typography py="12px">Previous</Typography>
            </Box>
            <h1 className='h1'>Upload Your Images</h1>
            <Typography variant="body2" color="common.white" pr={2} sx={{textAlign:'justify'}}>Step into the future with our revolutionary
             face recognition technology. No more passwords or keys required just a glance, and you're in. Discover security and 
             convenience like never before.</Typography>
             <Typography variant="body2" color="common.white" mt={1} pr={2} sx={{textAlign:'justify'}}>Important - Upload a maximum of 3 images don't exceed this limit.</Typography>
            <Box className="box-buttons">
              {/* <Button variant="contained" size='medium' className={classes.Button} onClick={addface}>Add face</Button> */}
              <TextField label="Name" name="Name" type="text" value={name} placeholder="Name" id="outlined" size="small" className={classes.TextField} onChange={(e)=>setName(e.target.value)} inputProps={{ style: {  color: 'white', }, }} InputLabelProps={{ style: { color: 'white' }, }} />
              <Button component="label" variant="contained" size='medium' className={classes.Button} disabled={isButtonDisabled} startIcon={<CloudUploadIcon />}>
                 Upload Images
                 <VisuallyHiddenInput type="file" accept="image/*" multiple onChange={handleFileUpload}/>
                 {/* accept=".zip" */}
              </Button>
            </Box>
            {loading && (
                <Box className='resultimageadd'>
                  <CircularProgress color='inherit'/> <Typography py="12px" ml={1}>Uploading...</Typography>
                </Box>
              )}
        </Box>
        <Box className="box2">
            <img src={facebg} className='faceimage' alt="face"></img>  
        </Box>
    </div>
  );
}

export default Addface;
