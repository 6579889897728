import './App.css';
import * as React from 'react';
import {  useRef , useCallback , useState } from 'react';
// import { Grid } from '@mui/material';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
// import Typography from '@mui/material/Typography';
// import Link from '@mui/material/Link';
import { makeStyles } from '@mui/styles';
import Webcam from "react-webcam";
import axios from 'axios';
import ArrowCircleLeftSharpIcon from '@mui/icons-material/ArrowCircleLeftSharp';
import IconButton from '@mui/material/IconButton';
import CircularProgress from '@mui/material/CircularProgress';
import { Typography } from '@mui/material';
import { useLocation } from 'react-router-dom';
import facebg1 from './images/Facial-Recognition-1.png';

const useStyles = makeStyles((theme) => ({
    Button: {
        background: 'linear-gradient(195deg, rgb(73, 163, 241), rgb(26, 115, 232))',
        borderRadius: 3,
        '&:hover': {
          backgroundColor: '#1662c4',
        }
      },
  }));

function Detectface() {
    const classes = useStyles()
    const location = useLocation();


    const webcamRef = useRef(null);
    const [imgSrc, setImgSrc] = useState(null);
    const [blink, setBlink] = useState(false);
    const [nodetect,setNoDetect] = useState('')
    const [loading,setLoading] = useState(false)
    const [camerashow ,setCamerashow] =useState(true)

    function dataURItoBlob(dataURI) {
      const byteString = atob(dataURI.split(',')[1]);
      const mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];
      const ab = new ArrayBuffer(byteString.length);
      const ia = new Uint8Array(ab);
  
      for (let i = 0; i < byteString.length; i++) {
          ia[i] = byteString.charCodeAt(i);
      }
  
      return new Blob([ab], { type: mimeString });
  }

    const capture = useCallback(() => {
      setLoading(true)
      setCamerashow(false)
        const imageSrc = webcamRef.current.getScreenshot();

        console.log(imageSrc)

         // Convert base64 to Blob
        const blob = dataURItoBlob(imageSrc);

        // Create FormData
        const formData = new FormData();
        formData.append('image', blob, 'screenshot.jpg'); // 'image' is the field name

        setBlink(true);
        axios.post('https://facerecognition.codebugged.com/test', formData)
            .then(response => {
              // Handle success
              console.log('Image sent successfully:', response);
              setLoading(false)
              setImgSrc(response.data.image)
              setNoDetect(response.data.message)
            })
            .catch(error => {
              // Handle error
              console.error('Error sending image:', error);
            });
            setTimeout(() => {
                setBlink(false);
              }, 200); 
      }, [webcamRef]);

      const onUserMedia = (e) => {
        console.log(e);
      };

      const previouspage = () => {
        if (location.pathname === '/detectface') {
          window.location.href = '/';
        }
      }
      
      const clear = () =>{
        setImgSrc('')
        setNoDetect('')
        setCamerashow(true)
      }

  return (
    <div className="App-1">
      {/* <div className="App1"> */}
        <Box className="box1">
            <Box className="box">
                <IconButton color='inherit' size="large"  onClick={previouspage}> 
                    <ArrowCircleLeftSharpIcon/> 
                </IconButton>
                <Typography py="12px">Previous</Typography>
            </Box>
            {camerashow && (
              <>
              <div className={`webcam ${blink ? 'blink' : ''}`}>
                  <Webcam className='webcam' ref={webcamRef} onUserMedia={onUserMedia} screenshotFormat="image/jpeg"/>
              </div>
              <Box className="box-buttons">
                  <Button variant="contained" size='medium'className={classes.Button} onClick={capture}>Capture photo</Button>
                  {/* {imgSrc && ( <Button variant="contained" size='medium'className={classes.Button} onClick={clear}>Clear</Button> )} */}
              </Box>
              </>
            )}
            {loading && (
              <Box className='resultimage'>
                <CircularProgress color='inherit'/>
              </Box>
            )}
            {imgSrc && (
              <Box className='resultimage'>
                {/* <Typography className='resulttypo' mb={2} variant='h5' sx={{textAlign:'left'}}>Result :</Typography> */}
                <img src={`data:image/jpeg;base64,${imgSrc}`} alt="Screenshot" className='detectresultimage' />
                <Box>
                  <Typography mt={2} mb={3} variant='h6' className=''>No of Persons Detected - {nodetect}</Typography>
                </Box>
                <Button variant="contained" size='medium'className={classes.Button} onClick={clear} sx={{width:'200px'}}>Take Another Photo</Button>
              </Box>
            )}
        </Box>
        <Box className="box2">
            <img src={facebg1} alt="face" className='detectintroimage'></img>
        </Box>
      {/* </div> */}
    </div>
    
  );
}

export default Detectface;