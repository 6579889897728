import React from "react";
import { BrowserRouter as Router, Routes, Route  } from "react-router-dom";
import './App.css';
import routes from './route';
import Intro from './intro';
import Addface from './addface';
import Detectface from './detecface';


export default function App() {
  const getRoutes = (allRoutes) =>
    allRoutes.map((route) => {
      if (route.collapse) {
        return getRoutes(route.collapse);
      }
      if (route.route) {
        return <Route exact path={route.route} element={route.component} key={route.key} />;
      }

      return null;
    });
  return (
    <div className="App">
      <Router>
        <Routes>
        {getRoutes(routes)}
          <Route path="/" component={< Intro/>} />
          <Route path="/addface" component={< Addface/>} />
          <Route path="/detectface" component={< Detectface/>} />
          {/* <Route path="*" element={<Navigate to="*" />} /> */}
        </Routes>
      </Router>
    </div>
  );
}